'use client'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Search } from 'lucide-react'
import { useState } from 'react'

export const ModalSearchBox: React.FC = () => {
  const [keyword, setKeyword] = useState<string>('')
  return (
    <div className="w-full mx-auto p-3.5 relative">
      <Input
        type="text"
        value={keyword}
        className=""
        onChange={(e) => setKeyword(e.target.value)}
        style={{ background: '#f5f5f5' }}
        placeholder="キーワードを入力してください"
      />
      <Button
        className="absolute right-2 top-0 h-full bg-transparent"
        onClick={() => {
          if (keyword === '') return
          window.location.href = `/itemlist?keyword=${keyword}`
        }}
      >
        <Search size={20} />
      </Button>
    </div>
  )
}

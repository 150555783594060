'use client'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

type ScrollDetectorProps = {
  children: React.ReactNode
}

export const ScrollDetector: React.FC<ScrollDetectorProps> = ({ children }) => {
  const [scrollY, setScrollY] = useState<number>(0)
  const path = usePathname()

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollY])

  const isVisible =
    scrollY > 150 &&
    !path.includes('item') &&
    !path.includes('articles') &&
    !path.includes('pages')

  return <div className={isVisible ? 'block' : 'hidden'}>{children}</div>
}

'use client'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { serviceType } from '@/lib/service'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

export function TopOptionalModal({
  component,
  text,
}: {
  service: serviceType
  text: string
  component: JSX.Element
}) {
  const [open, setOpen] = useState(false)
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(!open)
        }}
        className="cursor-pointer"
      >
        <div className="relative flex items-center">
          <p className="optional-header-text text-sm font-semibold">{text}</p>
          <div className="optional-header-icon ml-2 lg:hidden">
            <FontAwesomeIcon icon={faSearch} className="" />
          </div>
        </div>
      </DialogTrigger>
      <DialogContent
        className="p-3.5 border-0 bg-white w-11/12 rounded-md duration-500 overflow-y-auto max-h-[500px]"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <div className="mt-8">{component}</div>
      </DialogContent>
    </Dialog>
  )
}
